export const INVENTORY_UAM = {
  VIEW_PAGE: "cd25221b-0c8d-4f10-9e38-02446278c134",
};

export const MANAGE_CUSTOMER_UAM = {
  VIEW_PAGE: "20027ecc-6365-44b8-8549-23491c09c7f5",
};

export const MANAGE_ORDER_UAM = {
  VIEW_PAGE: "abf0d4e7-b5f0-49d9-9cfe-defd85dfd455",
};

export const MANAGE_CATEGORY_UAM = {
  VIEW_PAGE: "4bb8c594-7d0e-4b60-ba9f-dcf9f985a1b6",
};

export const MANAGE_REPRESENTATIVE_UAM = {
  VIEW_PAGE: "3b75f0d6-7001-4cc3-84bc-6b59f7d0ecd3",
};

export const MANAGE_UNIT_UAM = {
  VIEW_PAGE: "315c0829-6217-4fb2-a500-14bc722b8c6a",
};

export const MANAGE_VENDOR_CONTRACT_UAM = {
  VIEW_PAGE: "315c0849-6217-4fb2-a501-14bc722b8c6a",
};

export const MANAGE_SERVICE_UAM = {
  VIEW_PAGE: "58ce02f8-1d4b-4b0d-b09b-0465415d868b",
};

export const MANAGE_ARRIVAL_RECEIPT_UAM = {
  VIEW_PAGE: "c3b8cbd7-31f4-4baf-a2d0-b0f6ea91fc1e",
};

export const MANAGE_TRUCK_UAM = {
  VIEW_PAGE: "3fc0b1c3-eb73-4fa3-84a8-1c0176013f6e",
};

export const MANAGE_SHIP_UAM = {
  VIEW_PAGE: "7568734d-92fa-41c0-b687-20b771c5a547",
};

export const MANAGE_SHIP_VENDOR_UAM = {
  VIEW_PAGE: "6ed73533-5500-42f1-846f-82f705086642",
};

export const MANAGE_DEPO_UAM = {
  VIEW_PAGE: "9ca19d49-7089-4f94-b029-685a49e9f758",
};

export const MANAGE_PORT_UAM = {
  VIEW_PAGE: "8ca6cf27-01d4-4c57-8a3a-b491dae55a5f",
};

export const MANAGE_RECEIVER_UAM = {
  VIEW_PAGE: "29f717f2-538a-4683-ac67-55879c85876d",
};

export const MANAGE_USER_UAM = {
  VIEW_PAGE: "1b021e7e-532d-4017-baac-6ccdcd23de11",
};

export const MANAGE_ROLE_UAM = {
  VIEW_PAGE: "0c9b5a0e-a964-4177-a075-091f423792cc",
};

export const MANAGE_PORT_WAYBILL_OUTSIDE_DOCK_UAM = {
  VIEW_PAGE: "a4c1b95e-02f8-47ee-9d58-2361cdab13a7",
};

export const MANAGE_PORT_WAYBILL_INSIDE_DOCK_UAM = {
  VIEW_PAGE: "d0cfdd51-55fd-4564-a05e-1cfed95e0a69",
};

export const MANAGE_PACKER_UAM = {
  VIEW_PAGE: "315c0849-6217-4fb2-a500-14bc722b8c6a",
};

export const MANAGE_DRIVER_UAM = {
  VIEW_PAGE: "2c8485e8-349f-4d25-b77c-6534f8e5ebec",
};

export const MANAGE_VOYAGE_UAM = {
  VIEW_PAGE: "a060f59d-8915-4c0c-9e0f-f93c4c7712df",
};

export const MANAGE_PACKING_LIST_UAM = {
  VIEW_PAGE: "e294ae5e-6cfb-4bfc-9b5d-cc08ea0b6d03",
};

export const MANAGE_ORDER_BATCH_UAM = {
  VIEW_PAGE: "d71ba652-6a7c-44cc-a409-b379fc315c3f",
};

export const DRIVER_PAYOUT_UAM = {
  VIEW_PAGE: "2c8485e8-34za-4d25-b77c-6534f8e5ebec",
};

export const EXPORT_DATA_UAM = {
  VIEW_PAGE: "46dadba9-cdfc-4beb-b40a-6a17f66c0323",
};

export const MANAGE_INSURANCE_VENDOR_UAM = {
  VIEW_PAGE: "31vx0849-6217-4fb2-a500-14bc722b8c6a",
};

export const MANAGE_ADDITIONAL_FEE_UAM = {
  VIEW_PAGE: "a060f5fz-8915-4c0c-9e0f-f93c4c7712df",
};

export const MANAGE_ADDITIONAL_FEE_TEMPLATE_UAM = {
  VIEW_PAGE: "a060f5fz-8915-4c0c-9e0f-f93fqd7712df",
};

export const MANAGE_EVENT_REPORT_UAM = {
  VIEW_PAGE: "0990542c-3066-45ea-9a2c-7ad758c263b1",
};

export const INVOICE_UAM = {
  VIEW_PAGE: "0990542c-30fg-45ea-9a2c-7ad758c263b1",
};

export const MANAGE_PRICE_UAM = {
  VIEW_PAGE: "634b9842-fd72-4f90-a329-98addb68f1f8",
};

import React, { createContext, useContext, useReducer } from "react";
import { node } from "prop-types";

import reducer from "./reducer";
import initialContext from "./initial-context";

const getInitialContext = () => initialContext;
const GlobalContext = createContext(initialContext);
const useGlobalContext = () => useContext(GlobalContext);

function GlobalContextProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, {}, getInitialContext);

  const value = {
    ...state,
    dispatch,
  };

  console.log("GLOBAL_CONTEXT", { value });

  return (
    <GlobalContext.Provider {...{ value }}>{children}</GlobalContext.Provider>
  );
}

GlobalContextProvider.propTypes = {
  children: node.isRequired,
};

export { GlobalContextProvider, GlobalContext, useGlobalContext };

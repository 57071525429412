import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { RequireAuth } from "react-auth-kit";

import { Toaster } from "react-hot-toast";

import DefaultLayout from "./layout/DefaultLayout";

import routeConfig from "./routeConfig"; // Import the route configuration

function App() {
  return (
    <Router>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: "80vw",
            textAlign: "center",
            fontSize: "14px",
          },
        }}
      />
      <Routes>
        {routeConfig.map(({ path, component: Component, requiresAuth }) => {
          const routeElement = requiresAuth ? (
            <RequireAuth loginPath="/login">
              <DefaultLayout>
                <Component />
              </DefaultLayout>
            </RequireAuth>
          ) : (
            <Component />
          );

          return <Route key={path} path={path} element={routeElement} />;
        })}
      </Routes>
    </Router>
  );
}

export default App;

import { css } from "@emotion/css";

import colors from "./color";

const { primary, secondary, accent } = colors;

export const cssRootHTMLClass = css`
  .ant-menu {
    background-color: ${primary};
    font-weight: bold;
    font-size: 14px;
    color: white;
    font-weight: normal;
  }

  .ant-menu-item-selected {
    background-color: ${secondary};
    color: white;
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      background-color: ${secondary};
      color: white;
    }
  }

  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: ${accent};
    color: ${secondary};
    color: white;
  }

  .ant-layout-sider-children {
    background-color: ${primary} !important;
  }

  .ant-layout-sider-trigger {
    background-color: ${secondary} !important;
  }
`;

import React from "react";
import ReactDOM from "react-dom/client";

import { HelmetProvider } from "react-helmet-async";

import { ConfigProvider } from "antd";

import { AuthProvider } from "react-auth-kit";

import App from "./App";

import { GlobalContextProvider } from "./contexts";

import { cssRootHTMLClass } from "./style/style";
import colors from "./style/color";
import "./style/index.css";

const { primary, accent, background } = colors;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: primary,
        colorInfo: primary,
        colorBgBase: background,
        fontFamily: "Roboto, sans-serif",
      },
      components: {
        Input: {
          colorBgContainer: "#ffffff",
          colorBgContainerDisabled: "#d8d9da",
          algorithm: true,
        },
        InputNumber: {
          colorBgContainer: "#ffffff",
          colorBgContainerDisabled: "#d8d9da",
          algorithm: true,
        },
        Select: {
          colorBgContainer: "rgb(255, 255, 255)",
        },
        Button: {
          colorBgContainerDisabled: "rgba(220, 220, 220, 0.40)",
          colorPrimaryHover: "#2A421F",
        },
        Form: {
          itemMarginBottom: 16,
          verticalLabelPadding: "0 0 4px",
        },
        Tabs: {
          itemSelectedColor: accent,
          colorBgContainer: "#F7FFF0",
          colorBorderSecondary: background,
          cardBg: background,
        },
        DatePicker: {
          colorBgContainer: "#ffffff",
        },
        Checkbox: {
          colorBgContainer: "rgb(237, 250, 230)",
          colorBorder: "rgb(97, 143, 84)",
        },
      },
    }}
  >
    <HelmetProvider>
      <div className={cssRootHTMLClass}>
        <AuthProvider
          authType={"cookie"}
          authName={"_auth_bsb_"}
          cookieDomain={window.location.hostname}
          cookieSecure={false}
        >
          <GlobalContextProvider>
            <App />
          </GlobalContextProvider>
        </AuthProvider>
      </div>
    </HelmetProvider>
  </ConfigProvider>
);

const cases = {};

const reducer = (state, action) => {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("global context reducer", { state, action });
    }
    return cases[action.type](state, action.payload);
  } catch (error) {
    console.error("global context reducer error", { error, state, action });
  }
};

export default reducer;
